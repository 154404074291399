table.dataTable {
  clear: both;
  border-spacing: 0;
  max-width: none !important;
  border-collapse: separate !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

table.dataTable td, table.dataTable th {
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  text-align: left;
  white-space: nowrap;
  font-weight: normal;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  white-space: nowrap;
  text-align: left;
  font-weight: normal;
}

div.dataTables_wrapper div.dataTables_filter input {
  width: auto;
  margin-left: .5em;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_info {
  white-space: nowrap;
  padding-top: .85em;
}

div.dataTables_wrapper div.dataTables_paginate {
  white-space: nowrap;
  text-align: right;
  margin: 0;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  white-space: nowrap;
  justify-content: flex-end;
  margin: 2px 0;
}

div.dataTables_wrapper div.dataTables_processing {
  width: 200px;
  text-align: center;
  margin-top: -26px;
  margin-left: -100px;
  padding: 1em 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  opacity: .3;
  display: block;
  position: absolute;
  bottom: .9em;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  content: "↑";
  right: 1em;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  content: "↓";
  right: .5em;
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before, div.dataTables_scrollBody table thead .sorting_asc:before, div.dataTables_scrollBody table thead .sorting_desc:before, div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  border-top: none;
  margin-top: 0 !important;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: .85em;
}

table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child, table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td, div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}

div.dataTables_wrapper {
  padding: 0;
}

div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
  align-items: center;
  margin: 0;
  display: flex;
}

div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_info {
  justify-content: flex-start;
}

div.dataTables_wrapper div.dataTables_filter label, div.dataTables_wrapper div.dataTables_paginate, div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_length .form-control {
  margin: 0 .5rem;
}

[dir="rtl"] div.dataTables_wrapper div.dataTables_length label {
  text-align: right;
}

[dir="rtl"] div.dataTables_filter {
  text-align: left;
}

[dir="rtl"] div.dataTables_filter label {
  text-align: right;
}

[dir="rtl"] div.dataTables_filter input {
  margin-left: 0;
  margin-right: .5em;
}

div.dataTables_wrapper div.dataTables_info {
  white-space: normal;
  padding-top: .25rem;
}

[dir="rtl"] div.dataTables_wrapper div.dataTables_paginate {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  white-space: normal;
  flex-wrap: wrap;
  justify-content: center;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-link {
  outline: 0;
}

div.dataTables_wrapper [class*="col-md-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

table.dataTable {
  width: 100% !important;
  border-collapse: collapse !important;
}

[dir="rtl"] table.dataTable.table-sm > thead > tr > th {
  padding-left: 1.25rem;
}

[dir="rtl"] table.dataTable.table-sm .sorting:before, [dir="rtl"] table.dataTable.table-sm .sorting_asc:before, [dir="rtl"] table.dataTable.table-sm .sorting_desc:before {
  left: .85em;
  right: auto;
}

[dir="rtl"] table.dataTable thead > tr > th.sorting_asc, [dir="rtl"] table.dataTable thead > tr > th.sorting_desc, [dir="rtl"] table.dataTable thead > tr > th.sorting, [dir="rtl"] table.dataTable thead > tr > td.sorting_asc, [dir="rtl"] table.dataTable thead > tr > td.sorting_desc, [dir="rtl"] table.dataTable thead > tr > td.sorting {
  padding-left: 1.875rem;
}

table.dataTable thead th {
  vertical-align: middle;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  height: 1.25rem;
  margin-top: -.625rem;
  line-height: 1.25rem;
  top: 50%;
}

[dir="rtl"] table.dataTable thead .sorting:before, [dir="rtl"] table.dataTable thead .sorting_asc:before, [dir="rtl"] table.dataTable thead .sorting_desc:before, [dir="rtl"] table.dataTable thead .sorting_asc_disabled:before, [dir="rtl"] table.dataTable thead .sorting_desc_disabled:before {
  left: 1em;
  right: auto;
}

[dir="rtl"] table.dataTable thead .sorting:after, [dir="rtl"] table.dataTable thead .sorting_asc:after, [dir="rtl"] table.dataTable thead .sorting_desc:after, [dir="rtl"] table.dataTable thead .sorting_asc_disabled:after, [dir="rtl"] table.dataTable thead .sorting_desc_disabled:after {
  left: .5em;
  right: auto;
}

[dir="rtl"] table.table-bordered.dataTable th, [dir="rtl"] table.table-bordered.dataTable td {
  border-left-width: 1px;
  border-right-width: 0;
}

[dir="rtl"] table.table-bordered.dataTable th:last-child, [dir="rtl"] table.table-bordered.dataTable td:last-child {
  border-left-width: 0;
}

div.card-datatable.dataTable, div.card-datatable .dataTable {
  border-left: 0;
  border-right: 0;
}

.default-style div.dataTables_wrapper div.dataTables_length label, .default-style div.dataTables_wrapper div.dataTables_filter label {
  margin-bottom: .5rem;
}

.default-style div.dataTables_wrapper div.dataTables_info {
  color: #a3a4a6;
}

.default-style div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-link {
  min-width: calc(1.5rem + 2px);
  padding: .375rem .25rem;
  font-size: .75rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .default-style div.dataTables_wrapper div.dataTables_length label, .default-style div.dataTables_wrapper div.dataTables_filter label, .default-style div.dataTables_wrapper div.dataTables_info, .default-style div.dataTables_wrapper div.dataTables_paginate {
    justify-content: center;
  }
}

.default-style div.card-datatable {
  padding: 1.5rem 0;
}

.default-style div.card-datatable [class*="col-md-"] {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 768px) {
  .default-style:not([dir="rtl"]) div.dataTables_wrapper .row [class*="col-sm-"]:first-child {
    padding-right: 0 !important;
  }

  .default-style:not([dir="rtl"]) div.dataTables_wrapper .row [class*="col-sm-"]:last-child {
    padding-left: 0 !important;
  }
}

.default-style:not([dir="rtl"]) div.card-datatable table.dataTable thead th:first-child, .default-style:not([dir="rtl"]) div.card-datatable table.dataTable tbody td:first-child, .default-style:not([dir="rtl"]) div.card-datatable table.dataTable tfoot th:first-child {
  padding-left: 1.5rem;
}

.default-style:not([dir="rtl"]) div.card-datatable table.dataTable thead th:last-child, .default-style:not([dir="rtl"]) div.card-datatable table.dataTable tbody td:last-child, .default-style:not([dir="rtl"]) div.card-datatable table.dataTable tfoot th:last-child {
  padding-right: 1.5rem;
}

.default-style[dir="rtl"] table.dataTable.table-sm > thead > tr > th {
  padding-right: .3125rem;
}

@media (min-width: 768px) {
  .default-style[dir="rtl"] div.dataTables_wrapper .row [class*="col-sm-"]:first-child {
    padding-left: 0 !important;
  }

  .default-style[dir="rtl"] div.dataTables_wrapper .row [class*="col-sm-"]:last-child {
    padding-right: 0 !important;
  }
}

.default-style[dir="rtl"] table.dataTable thead th, .default-style[dir="rtl"] table.dataTable tbody td, .default-style[dir="rtl"] table.dataTable tfoot th {
  padding-right: .625rem;
}

.default-style[dir="rtl"] table.dataTable.table-sm thead th, .default-style[dir="rtl"] table.dataTable.table-sm tbody td, .default-style[dir="rtl"] table.dataTable.table-sm tfoot th {
  padding-right: .3125rem;
}

.default-style[dir="rtl"] div.card-datatable table.dataTable thead th:first-child, .default-style[dir="rtl"] div.card-datatable table.dataTable tbody td:first-child, .default-style[dir="rtl"] div.card-datatable table.dataTable tfoot th:first-child {
  padding-right: 1.5rem;
}

.default-style[dir="rtl"] div.card-datatable table.dataTable thead th:last-child, .default-style[dir="rtl"] div.card-datatable table.dataTable tbody td:last-child, .default-style[dir="rtl"] div.card-datatable table.dataTable tfoot th:last-child {
  padding-left: 1.5rem;
}

.material-style div.dataTables_wrapper div.dataTables_length label, .material-style div.dataTables_wrapper div.dataTables_filter label {
  margin-bottom: .5rem;
}

.material-style div.dataTables_wrapper div.dataTables_info {
  color: #a3a4a6;
}

.material-style div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-link {
  min-width: 1.5rem;
  padding: .375rem .25rem;
  font-size: .75rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .material-style div.dataTables_wrapper div.dataTables_length label, .material-style div.dataTables_wrapper div.dataTables_filter label, .material-style div.dataTables_wrapper div.dataTables_info, .material-style div.dataTables_wrapper div.dataTables_paginate {
    justify-content: center;
  }
}

.material-style div.card-datatable {
  padding: 1.5rem 0;
}

.material-style div.card-datatable [class*="col-md-"] {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

@media (min-width: 768px) {
  .material-style:not([dir="rtl"]) div.dataTables_wrapper .row [class*="col-sm-"]:first-child {
    padding-right: 0 !important;
  }

  .material-style:not([dir="rtl"]) div.dataTables_wrapper .row [class*="col-sm-"]:last-child {
    padding-left: 0 !important;
  }
}

.material-style:not([dir="rtl"]) div.card-datatable table.dataTable thead th:first-child, .material-style:not([dir="rtl"]) div.card-datatable table.dataTable tbody td:first-child, .material-style:not([dir="rtl"]) div.card-datatable table.dataTable tfoot th:first-child {
  padding-left: 1.5rem;
}

.material-style:not([dir="rtl"]) div.card-datatable table.dataTable thead th:last-child, .material-style:not([dir="rtl"]) div.card-datatable table.dataTable tbody td:last-child, .material-style:not([dir="rtl"]) div.card-datatable table.dataTable tfoot th:last-child {
  padding-right: 1.5rem;
}

.material-style[dir="rtl"] table.dataTable.table-sm > thead > tr > th {
  padding-right: .3125rem;
}

@media (min-width: 768px) {
  .material-style[dir="rtl"] div.dataTables_wrapper .row [class*="col-sm-"]:first-child {
    padding-left: 0 !important;
  }

  .material-style[dir="rtl"] div.dataTables_wrapper .row [class*="col-sm-"]:last-child {
    padding-right: 0 !important;
  }
}

.material-style[dir="rtl"] table.dataTable thead th, .material-style[dir="rtl"] table.dataTable tbody td, .material-style[dir="rtl"] table.dataTable tfoot th {
  padding-right: .625rem;
}

.material-style[dir="rtl"] table.dataTable.table-sm thead th, .material-style[dir="rtl"] table.dataTable.table-sm tbody td, .material-style[dir="rtl"] table.dataTable.table-sm tfoot th {
  padding-right: .3125rem;
}

.material-style[dir="rtl"] div.card-datatable table.dataTable thead th:first-child, .material-style[dir="rtl"] div.card-datatable table.dataTable tbody td:first-child, .material-style[dir="rtl"] div.card-datatable table.dataTable tfoot th:first-child {
  padding-right: 1.5rem;
}

.material-style[dir="rtl"] div.card-datatable table.dataTable thead th:last-child, .material-style[dir="rtl"] div.card-datatable table.dataTable tbody td:last-child, .material-style[dir="rtl"] div.card-datatable table.dataTable tfoot th:last-child {
  padding-left: 1.5rem;
}

/*# sourceMappingURL=index.cbe09446.css.map */
